/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import './Layout.css';
import Header from './Header/Header';
import NavBar from './NavBar/NavBar';
import CollapsedNavBar from './CollapedNavBar/CollapsedNavBar';
import Footer from './Footer/Footer';

const Layout = ({ children }) => {
  const [ spinner, setSpinner ] = useState(true);

  useEffect(() => {
    setTimeout(() => setSpinner(false), 2000)
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return spinner ? <div className="loading"><div className="preloader"></div></div> : !spinner && (
    <>
      <Header />
      <NavBar siteTitle={data.site.siteMetadata.title} />
      <CollapsedNavBar siteTitle={data.site.siteMetadata.title} />
      <main className="content">{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
