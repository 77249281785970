import React from 'react';
import style from './Header.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYelp, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    return(
        <header className={style.Header}>
            <div className="wrapper">
                <div className={style.Container}>
                    <div className={style.Social}>
                        <ul className={style.SocialIcons}>
                            <li><a href="https://www.facebook.com/pages/category/Garage-Door-Service/Garage-Door-Master-101952908081254">
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                />
                            </a></li>
                            <li><a href="https://m.yelp.com/biz/garage-door-master-streamwood-2">
                                <FontAwesomeIcon
                                    icon={faYelp}
                                />
                            </a></li>
                            <li><a href="https://www.instagram.com/masterfinishil">
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                />
                            </a></li>
                        </ul>
                    </div>
                    <ul className={style.Contact}>
                        <li>
                            <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                            />
                            Address
                            <a href="https://www.google.com/maps/place/1086+Frances+Dr,+Streamwood,+IL+60107/@42.0105293,-88.1517097,17z/data=!3m1!4b1!4m5!3m4!1s0x880fa9c76e91a449:0xef24e44bc459e250!8m2!3d42.0105293!4d-88.149521">1086 Frances Dr, Streamwood</a>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faPhoneAlt}
                            />
                            Call/Text
                            <a href="tel:8475581117">(847)558-1117</a>
                        </li>
                        <li>
                            <FontAwesomeIcon
                                icon={faClock}
                            />
                            Open Hours
                            <a href="#">Mon-Fri: 8am - 5:30pm</a>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Header;