import PropTypes from "prop-types"
import React, { useState } from 'react';
import './NavBar.css';
import Button from '../Button/Button';
import { useScrollPosition } from '../../hooks/useScrollPosition';

const NavBar = ({ siteTitle }) => {
  const [ hideOnScroll, setHideOnScroll ] = useState(false);

  useScrollPosition(({ currPos }) => {
    const isShow = currPos.y < -72;
    if(isShow !== hideOnScroll) setHideOnScroll(isShow)
  }, [hideOnScroll]);

  return (
    <header className={`NavBar${hideOnScroll ? ' sticky' : ''}`}>
      <div className="wrapper">
        <div className="NavContainer">
          <h1>
            <a href="#home">
              {siteTitle}
            </a>
          </h1>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#contact">Contact</a>
          <Button link="#contact"label="Book today" />
        </div>
      </div>
    </header>
  );
};

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar;
