import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react';
import './CollapsedNavBar.css';
import { useScrollPosition } from '../../hooks/useScrollPosition';

const NavBar = ({ siteTitle }) => {
  const node = useRef()
  const [ hideOnScroll, setHideOnScroll ] = useState(false);
  const [ isMenuOpen, setMenuOpen ] = useState(false);

  useScrollPosition(({ currPos }) => {
    const isShow = currPos.y < -215;
    if(isShow !== hideOnScroll) setHideOnScroll(isShow)
  }, [hideOnScroll]); 

  const handleClickOutside = e => {
    if(node.current.contains(e.target)) {
      return;
    }
    setMenuOpen(false);
  }

  useEffect(() => {
    if(isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <header 
      ref={node}
      className={`CollapsedNavBar${hideOnScroll ? ' sticky' : ''}`}>
      <div className="wrapper">
        <div className="CollapsedNavContainer">
          <h1>
            <a href="#home">
              Garage Door Master
            </a>
          </h1>
          <div className={`NavItems${isMenuOpen ? ' open' : ''}`}>
            <a
              href="#home"
              onClick={() => setMenuOpen(!isMenuOpen)}  
            >Home</a>
            <a 
              href="#about"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >About</a>
            <a
              href="#services"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >Services</a>
            <a
              href="#contact"
              onClick={() => setMenuOpen(!isMenuOpen)}
            >Contact</a>
          </div>
          <button 
            className={`NavBtn${isMenuOpen ? ' change' : ''}`}
            onClick={() => setMenuOpen(!isMenuOpen)}>
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </button>
        </div>
      </div>
    </header>
  );
};

NavBar.propTypes = {
  siteTitle: PropTypes.string,
}

NavBar.defaultProps = {
  siteTitle: ``,
}

export default NavBar;
